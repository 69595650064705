<template>
    <div class="panel has-text-weight-semibold">
    <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
        <div class="columns is-mobile">

            <div class="column is-size-6 has-text-centered"> INSTANT &nbsp;LOTTERY &nbsp;AUDITS</div>
                <div class="level-right has-text-centered">
                    <div class="button is-primary has-text-white is-small"  @click="doAction('add')">
                        <i class="fas fa-file-invoice-dollar"></i>&nbsp;&nbsp;New</div>&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
             
            </div>           
    </div>
        <div class="columns">
            <div class="column is-4 has-text-left">
                <date-range-selector
                    :dateRange="dateRange"
                    @set-date-range="setDateRange"
                    >
                </date-range-selector>
            </div>
        </div> 
        <div id="ReportContent">
            <div class="has-text-weight-bold"  v-if="isPrinting">
                <div class="columns">
                    <div class="column is-12">
                        <div class="columns has-text-centered has-text-weight-bold">
                            <div class="column is-size-2 is-12">{{ getActiveStore.storeName }}</div>
                        </div>
                        <div class="columns has-text-centered has-text-weight-bold">
                            <div class="column is-size-4 is-12"> INSTANT LOTTERY AUDIT    
                            </div>
                        </div>                                                         
                    </div>
                </div> 
            </div>            
            <b-table 
                :data='auditRecords'
                :paginated="isPaginated"
                :per-page="perPage"
                paginationPosition="bottom"
                sortIcon="arrow-up"
                sortIconSize="is-small"
                default-sort=auditDate
                details
                detail-key="auditDate"
                defaultSortDirection='desc'
                >         

                <template>

                    <b-table-column class="is-2 has-text-right" field="Edit" label="Edit" v-slot="props">
                        <b-tooltip position="is-left" label="Spot Check Details" dashed >
                            <b-button class="is-primary is-small"  v-on:click="doAction('edit',props.row)" outlined rounded><i class="far fa-edit"></i></b-button>
                        </b-tooltip> 
                    </b-table-column> 

                    <b-table-column field="auditDate" label="Date" v-slot="props" sortable sorticon>
                        {{ props.row.auditDate.substr(0,10) | formatDate }}
                    </b-table-column>

                    <b-table-column field="auditerName" label="Audited By" v-slot="props" sortable sorticon>
                        <span class="is-capitalized">{{props.row.auditerName.toLowerCase() }}</span> 
                    </b-table-column>

                    <b-table-column numeric field="previousBookBalance" label="Previous Balance" v-slot="props" sortable sorticon>
                        {{ (props.row.missingBookValue > 0.00 ? props.row.missingBookValue : 0) |formatCurrency }} 
                    </b-table-column>

                    <b-table-column numeric field="actualBookValueSafe" label="In Safe" v-slot="props" sortable sorticon>
                        {{ parseFloat(props.row.actualBookValueSafe) |formatCurrency }} 
                    </b-table-column>

                    <b-table-column numeric field="actualBookValueRack" label="Inside Sale" v-slot="props" sortable sorticon>
                        {{ parseFloat(props.row.actualBookValueRack) |formatCurrency }} 
                    </b-table-column>

                    <b-table-column numeric field="actualBookValueMachine" label="Outside Sale" v-slot="props" sortable sorticon>
                        {{ parseFloat(props.row.actualBookValueMachine) |formatCurrency }} 
                    </b-table-column> 
       
                    <b-table-column numeric field="auditDayAdjustment" label="Audit Day Adjustment" v-slot="props" sortable sorticon>
                        {{ auditDayAdjustment(props.row) |formatCurrency }} 
                    </b-table-column>
                    <b-table-column numeric field="bookBalance" label="Book Balance" v-slot="props" sortable sorticon>
                        {{ parseFloat(props.row.bookBalance ? props.row.bookBalance : 0) |formatCurrency }} 
                    </b-table-column>                    
                    
                    <b-table-column numeric field="totalAuditedValue" label="Audited Value" v-slot="props" sortable sorticon>
                        {{ totalAuditedAfterAuditDayAdjustment(props.row) | formatCurrency }}
                    </b-table-column>

                    <b-table-column numeric field="variance" label="Variance" v-slot="props" sortable sorticon >
                        {{ totalVariance(props.row) |formatCurrency }}
                    </b-table-column>

                </template>
                <template slot="empty">
                    <data-loading 
                        :isLoading="isLoading" 
                        :isFullPage="isFullPage"> 
                    </data-loading>
                </template>

            </b-table>

            <lottery-audit-form
                ref="lotteryAuditForm"
                :isEditable="isEditable"
                :isShowAuditDialog="isShowAuditDialog"
                :canCancelDialog="canCancelDialog"
                :auditRecord="auditRecord"
                :inSafeBooks="inSafeRecords"
                :insideSaleRecords="insideSaleRecords"
                :outsideSaleRecords="outsideSaleRecords"
                :groupedInSafeBooks="groupedInSafeBooks"
                :partialLotteryBooks="partialLotteryBooks"
                :numberFields="numberFields"
                :countryCurrencyCode="countryCurrencyCode"
                @set-scanned-flag="setScannedFlag"
                @clear-scanned-flag="clearScannedFlag"
                @save-record="saveRecord"
                @hide-dialog="toggleAuditDialog">
            </lottery-audit-form>
                        
        </div>       
    </div>
</template>

<script>

import UserStore from "../../../../../store/UserStore"

import DataLoading from '../../../../../components/app/DataLoading'
import DateRangeSelector from "../../../../../components/app/DateRangeSelector"
import axios from 'axios'
import moment from 'moment'

import LotteryAuditForm from './LotteryAuditForm.vue'

export default {

    components: {
        DataLoading,
        LotteryAuditForm,
        DateRangeSelector
        
    },

    metaInfo: {
        title: 'Lottery Spot Check'
    },

    data() {
        return {
            isEditable: false,
            isPrinting: false,
            isPaginated: true,
            perPage: 31,
            isLoading: true,
            isFullPage: true,
            isShowAuditDialog: false,
            canCancelDialog: true,
            defaultScanFlag: true,
            inSafeRecords: [],
            insideSaleRecords: [],
            outsideSaleRecords: [],
            auditDateString: [],
            booksNotScanned: [],
            allLotteryBooks: [],
            partialLotteryBooks: [],
            groupedInSafeBooks: [],
            dateRange: [],
            auditRecords: [],
            auditRecord: {},
            auditDaySales: 0,
            auditedTicketCount: 0,
            numberFields: [
                "actualBookValueSafe",
                "actualBookValueRack",
                "actualBookValueMachine",
                "previousBookValueSafe",
                "previousBookValueRack",
                "previousBookValueMachine",
                "previousBookBalance",
                "inventoryBookValueSafe",
                "inventoryBookValueRack",
                "inventoryBookValueMachine",
                "booksReceivedTotalValueBetweenAudits",
                "returnedTicketsTotalValueBetweenAudits",
                "insideTotalValueBetweenAudits",
                "lotteryMachineWithdraw",
                "missingBookValue",
                "bookBalance",
                "auditDayInsideSales",
                "auditDayOutsideSales",
                "auditDayPurchase",
                "auditDayReturn",
                "missingTicketsTotalValueCurrentScan",
                "totalAuditedValue",
                "calculatedBookValueSafe"
            ],
            soundBookFoundOnSale: new Audio('/sounds/ScanBookFoundOnSale.mp3'),
            soundBookFoundInSafe: new Audio('/sounds/ScanBookFoundInSafe.mp3'),
            soundBookNotFoundOnSaleOrInSafe: new Audio('/sounds/ScanBookNotFoundOnSaleOrInSafe.mp3'),
            soundBookEndingSequenceLess: new Audio('/sounds/ScanBookEndingSequenceLess.mp3'),
            countryCurrencyCode: UserStore.getters.getCountryCurrencyCode

        }
    },

    methods: {

        async fetchRecords(status) {
            if (UserStore.getters.getUser.activeStore) {
                var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                    + UserStore.getters.getActiveStore.storeId + "/lottery/books"
                switch (status) {
                    case "ONSALE":
                        url = url + "?status=" + "ONSALE&location=R"
                        break
                    case "MACHINE":
                        url = url + "?status=" + "ONSALE&location=M"
                        break
                    case "RECEIVED":
                        url = url + "?status=RECEIVED"
                        break
                    case "AUDIT":
                        url = process.env.VUE_APP_API_ROOT_URL + '/stores/' +
                                UserStore.getters.getActiveStore.storeId + 
                            "/lottery/spotcheckaudit/" + this.spotCheckDate.toISOString().split('T')[0] 
                        break
                }
                
                await axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        switch (status) {
                            case "RECEIVED":
                                this.inSafeRecords = response.data.data
                                this.inSafeRecords.forEach(item => {
                                    item.scannedFlag = false
                                    item.source = 'insafe'
                                    item.auditedBookCount = 0
                                    item.auditedTicketCount = 0
                                    item.auditedAmount = 0
                                    item.previousStatus = item.status
                                    item.currentTicketNumber = item.lastTicketSold
                                })
                                this.auditRecord.inventoryBookValueSafe = this.inSafeRecords.reduce((sum, item) => {
                                    return sum + ((item.ticketCount - item.lastTicketSold) * item.pricePoint)
                                    }, 0)                                   
                                break
                            case "ONSALE":
                                this.insideSaleRecords = response.data.data
                                this.insideSaleRecords.forEach(item => {
                                    item.scannedFlag = false
                                    item.source = 'inside'
                                    item.auditedBookCount = 0
                                    item.auditedTicketCount = 0
                                    item.auditedAmount = 0
                                    item.previousStatus = item.status
                                    item.currentTicketNumber = item.lastTicketSold
                                })                                
                                this.auditRecord.inventoryBookValueRack = this.insideSaleRecords.reduce((sum, item) => {
                                    return sum + ((parseInt(item.ticketCount) - parseInt(item.lastTicketSold)) * parseInt(item.pricePoint))
                                    }, 0)
                                break
                            case "MACHINE":
                                this.outsideSaleRecords = response.data.data
                                this.outsideSaleRecords.forEach(item => {
                                    item.scannedFlag = false
                                    item.source = 'outside'
                                    item.auditedBookCount = 0
                                    item.auditedTicketCount = 0
                                    item.auditedAmount = 0
                                    item.previousStatus = item.status
                                    item.currentTicketNumber = item.lastTicketSold
                                })
                                this.auditRecord.inventoryBookValueMachine = this.outsideSaleRecords.reduce((sum, item) => {
                                    return sum + ((item.ticketCount - item.lastTicketSold) * item.pricePoint)
                                    }, 0)
                                       
                                break    
                        }                      
                    })
                    .catch( (err) => {
                        this.records = []
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })

            }
            
        },

        async fetchAuditList() {
            
            if (UserStore.getters.getUser.activeStore) {

                var startDate = this.dateRange[0].toISOString().split('T')[0] 
                var endDate = this.dateRange[1].toISOString().split('T')[0]
                
                const url = process.env.VUE_APP_API_ROOT_URL + "stores/" +
                            UserStore.getters.getActiveStore.storeId +
                            "/lottery/spotcheckauditlist" +
                             "?fromDate="+startDate+"&toDate="+endDate

                await axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.auditRecords = response.data.data
                        this.auditRecords.forEach(audit => {

                            // audit.variance = ((audit.bookBalance && audit.totalAuditedValue) ? audit.totalAuditedValue - audit.bookBalance : 0)
                            audit.variance = audit.totalAuditedValue
                                    - audit.auditDayPurchase
                                    + audit.auditDayOutsideSales
                                    + audit.auditDayInsideSales
                                    + audit.auditDayReturn
                            if (audit.safeScanFlag === null) {
                                audit.safeScanFlag = true
                            }
                            audit.totalScannedValue = this.getNumber(audit.actualBookValueRack) +
                                this.getNumber(audit.actualBookValueMachine) +
                                this.getNumber(audit.actualBookValueSafe) -
                                this.getNumber(audit.missingBookValue)
                            // audit.auditDayInsideSales = audit.totalScannedValue - audit.totalAuditedValue
                        })


                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }

        }, 

        async fetchAuditRecord() {
            this.auditDateString = moment(this.auditDate).format('YYYY-MM-DD')
            var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' +
                    UserStore.getters.getActiveStore.storeId + 
                "/lottery/spotcheckaudit/" + this.auditDateString

            var firstDayOfMonth = moment(this.auditDate).startOf('month').format('YYYY-MM-DD')
            url = url + "?startingDate="+firstDayOfMonth

            await axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken,
                        'correlation-id': this.$uuid.v1()
                    },                   
                })
                .then( (response) => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                    this.auditRecord = response.data.data

                    this.auditRecord.auditDate = moment(this.auditRecord.auditDate, 'YYYY-MM-DD').toDate()

                    this.numberFields.forEach(element => {
                        this.auditRecord[element] = this.getNumber(this.auditRecord[element])
                    })
                    if (this.auditRecord.safeScanFlag == null) {
                        this.auditRecord.safeScanFlag = this.defaultScanFlag
                    }

                    if (this.auditRecord.auditDate == "Invalid Date") {
                        this.auditRecord.auditDate = new Date
                    }

                    if (this.auditRecord.lotteryBookSpotCheckScanDataDTOList == null) {
                        this.auditRecord.lotteryBookSpotCheckScanDataDTOList = []
                    }
                    
                    this.setEditable() 
                                                    
                    this.isLoading = false
                })
                .catch( (err) => {
                    this.records = []
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                    this.isLoading = false
                    if (err.response.status === 403) {
                        alert('Session Expired. Please log in again!')
                        UserStore.commit('userLogout')
                    }
                })
            return
        },

        async fetchAuditDaySales() {
            if (UserStore.getters.getUser.activeStore) {

                var auditDate = moment(this.auditDate).format('YYYY-MM-DD')

                this.auditDaySales = 0.00
                
                const url = process.env.VUE_APP_API_ROOT_URL + "stores/" +
                            UserStore.getters.getActiveStore.storeId +
                            "/lottery/sales" +
                             "?from="+auditDate+"&to="+auditDate

                await axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        var salesToday = response.data.data
                        this.auditRecord.auditDayInsideSales = 0
                        if (this.auditDate == new Date()) {
                            salesToday.forEach(book => {
                                var soldTickets = book.lotteryGameSales.reduce((sum, item) => sum + item.ticketsSold, 0)
                                this.auditRecord.auditDayInsideSales += soldTickets * (book.totalValue / book.ticketCount)
                            })
                        }

                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log("Failed to set Store State", err)
                        this.auditDaySales = 0
                    })
            }
            return
        },

        async fetchBookInventory() {
            await this.fetchRecords("RECEIVED")
            await this.fetchRecords("ONSALE")
            await this.fetchRecords("MACHINE")
            this.allLotteryBooks = await this.combineAllBooks() 
            this.groupedInSafeBooks = await this.groupBooksByPrice(this.allLotteryBooks,'insafe') 
            
        },

        async postLotteryAudit(auditCopy) {
            // auditCopy.id = null
            var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' +
                                 UserStore.getters.getActiveStore.storeId + 
                                "/lottery/spotcheckaudit"
            return axios.post(url,
                auditCopy,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken,
                        'correlation-id': this.$uuid.v1()
                    },                   
                })
                .then( (response) => {
                    this.fetchAuditList()
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                })                               
        },        

        async saveRecord() {
            var auditCopy = Object.assign({}, this.auditRecord)
            auditCopy.auditDate = moment(auditCopy.auditDate).format('YYYY-MM-DD')

            this.numberFields.forEach(element => {
                this.auditRecord[element] = this.getNumber(this.auditRecord[element])
            })

            auditCopy.bookBalance = this.auditRecord.lastMonthEndInstantLotteryInventory 
                                        + this.auditRecord.booksReceivedTotalValueBetweenAudits 
                                        - this.auditRecord.returnedTicketsTotalValueBetweenAudits
                                        - this.auditRecord.insideTotalValueBetweenAudits
                                        - this.auditRecord.lotteryMachineWithdraw

            // Temorary Work Around
            auditCopy.missingBookValue = this.auditRecord.lastMonthEndInstantLotteryInventory
            // auditCopy.lotteryBookSpotCheckScanDataDTOList = []
            auditCopy.lotteryBookSpotCheckScanDataDTOList = []
            this.groupedInSafeBooks.forEach((group) => {
                group.books.forEach((book) => {
                    var bookSold = (book.lastTicketSold === null ? 0 : book.lastTicketSold)
                    var curTicket = (book.currentTicketNumber === null ? 0 : book.currentTicketNumber)
                    var scanAuditlocation = ""
                    if (book.scannedFlag === true) {
                        scanAuditlocation = 1
                    } else {
                        scanAuditlocation = 0
                    }
                    var bookCopy = {
                        "id": null,
                        "currentTicketNumber": curTicket,
                        "lastTicketSold": bookSold,
                        "ticketCountMissing": bookSold - curTicket,
                        "bookId": (auditCopy.id ? book.bookId : book.id),
                        "auditId": null,
                        "scanAuditlocation": scanAuditlocation
                    }
                    auditCopy.lotteryBookSpotCheckScanDataDTOList.push(bookCopy)
                })
            })

            this.insideSaleRecords.forEach((book) => {
                var bookSold = (book.lastTicketSold === null ? 0 : book.lastTicketSold)
                var curTicket = (book.currentTicketNumber === null ? 0 : book.currentTicketNumber)
                var scanAuditlocation = ""
                if (book.scannedFlag === true) {
                    scanAuditlocation = 3
                } else {
                    scanAuditlocation = 2
                }
                var bookCopy = {
                    "id": null,
                    "currentTicketNumber": curTicket,
                    "lastTicketSold": bookSold,
                    "ticketCountMissing": bookSold - curTicket,
                    "bookId": (auditCopy.id ? book.bookId : book.id),
                    "auditId": null,
                    "scanAuditlocation": scanAuditlocation
                }
                auditCopy.lotteryBookSpotCheckScanDataDTOList.push(bookCopy)
            })

            this.outsideSaleRecords.forEach((book) => {
                var bookSold = (book.lastTicketSold === null ? 0 : book.lastTicketSold)
                var curTicket = (book.currentTicketNumber === null ? 0 : book.currentTicketNumber)
                var scanAuditlocation = ""
                if (book.scannedFlag === true) {
                    scanAuditlocation = 5
                } else {
                    scanAuditlocation = 4
                }
                var bookCopy = {
                    "id": null,
                    "currentTicketNumber": curTicket,
                    "lastTicketSold": bookSold,
                    "ticketCountMissing": bookSold - curTicket,
                    "bookId": (auditCopy.id ? book.bookId : book.id),
                    "auditId": null,
                    "scanAuditlocation": scanAuditlocation
                }
                auditCopy.lotteryBookSpotCheckScanDataDTOList.push(bookCopy)
            })

            await this.postLotteryAudit(auditCopy)
            await this.toggleAuditDialog()
            this.$buefy.toast.open({
                message: "Lottery Spot Check Saved.",
                        type: 'is-success'
            })             
        },

        async combineAllBooks() {
            return [...this.inSafeRecords, ...this.insideSaleRecords, ...this.outsideSaleRecords]
        },

        async groupBooksByPrice(array,filter) {
            if (array.length == 0) {
                return []
            }
            var lotteryBooks = []
            var sortedByPrice = this.sortArrayByProp(array, 'pricePoint', 'asc')
            switch (filter) {
                case 'insafe':
                    lotteryBooks = sortedByPrice.filter(item => item.status == "RECEIVED")
                    break
                case 'inside':
                    lotteryBooks = sortedByPrice.filter(item => item.status == "ONSALE" && item.location == "R")
                    break
                case 'outside':
                    lotteryBooks = sortedByPrice.filter(item => item.status == "ONSALE" && item.location == "M")
                    break

            }

            var currentPricePoint = 0
            var currentTicketCount = 0
            var groupedRecords = []
            lotteryBooks.forEach((item) => {
        
                if (item.pricePoint === currentPricePoint && item.ticketCount === currentTicketCount ) {
                    groupedRecords[groupedRecords.length-1].books.push(item)
                    groupedRecords[groupedRecords.length-1].totalSold += (item.lastTicketSold ? item.lastTicketSold : 0)
                    groupedRecords[groupedRecords.length-1].count++
                    groupedRecords[groupedRecords.length-1].partialBookCount += (item.lastTicketSold? 1: 0)
                    groupedRecords[groupedRecords.length-1].auditedCount += (item.scannedFlag ? 1 : 0)
                } else {
                    currentPricePoint = item.pricePoint
                    currentTicketCount = item.ticketCount
                    var newGroup = {}
                    newGroup.pricePoint = item.pricePoint
                    newGroup.ticketCount = item.ticketCount
                    newGroup.totalSold = (item.lastTicketSold ? item.lastTicketSold : 0)
                    newGroup.partialBookCount = (item.lastTicketSold ? 1 : 0)
                    newGroup.count = 1
                    newGroup.auditedCount = (item.scannedFlag ? 1 : 0)
                    newGroup.books = [item]
                    groupedRecords.push(newGroup)
                }
            })
            return this.sortArrayByProp(groupedRecords, 'pricePoint', 'asc')
        },

        setDateRange(dateRange) {
            this.dateRange = [dateRange[0], dateRange[1]]
        },

        setEditable() {
            var today = moment().format('YYYY-MM-DD')
            var auditDate = moment(this.auditRecord.auditDate).format('YYYY-MM-DD')
            this.isEditable = (today === auditDate)
        },

        toggleAuditDialog() {
            this.isShowAuditDialog = !this.isShowAuditDialog
        },

        async doAction(action, row) {
            const loadingComponent = this.$buefy.loading.open({
                    container: this.isFullPage ? null : this.$refs.element.$el
                })                
            switch (action) {
                case "add":
                    this.auditDate = new Date()
                    
                    await this.fetchAuditRecord()
                    await this.fetchAuditDaySales()
                    if (this.auditRecord.id) {
                        await this.loadAuditRecord() 
                    } else {
                    
                        await this.fetchBookInventory()
                        this.numberFields.forEach(element => {
                            this.auditRecord[element] = this.getNumber(this.auditRecord[element])
                        })

                        this.auditRecord.inventoryBookValueSafe = this.inSafeRecords.reduce((sum, item) => {
                            return sum + ((item.ticketCount - item.lastTicketSold) * item.pricePoint)
                            }, 0)
                        this.auditRecord.inventoryBookValueRack = this.insideSaleRecords.reduce((sum, item) => {
                            return sum + ((item.ticketCount - item.lastTicketSold) * item.pricePoint)
                            }, 0)
                        this.auditRecord.inventoryBookValueMachine = this.outsideSaleRecords.reduce((sum, item) => {
                            return sum + ((item.ticketCount - item.lastTicketSold) * item.pricePoint)
                            }, 0)

                        this.auditRecord.inventory = this.auditRecord.inventoryBookValueSafe
                            + this.auditRecord.inventoryBookValueRack 
                            + this.auditRecord.inventoryBookValueMachine

                        
                    }
                    this.toggleAuditDialog()
                    break                    
                case "edit":
                    this.auditDate = moment(row.auditDate, 'YYYY-MM-DD').toDate()
                    await this.fetchAuditRecord()
                    await this.fetchAuditDaySales()
                    await this.loadAuditRecord()           
                    this.toggleAuditDialog()
                    break
            }
            loadingComponent.close()
        },

        async loadAuditRecord() {
            // if (this.auditRecord.actualBookValueSafe == "" || this.auditRecord.actualBookValueSafe === null) {
            //     this.auditRecord.actualBookValueSafe = 0
            // }
            // if (this.auditRecord.actualBookValueRack == "" || this.auditRecord.actualBookValueRack === null) {
            //     this.auditRecord.actualBookValueRack = 0
            // }
            // if (this.auditRecord.actualBookValueMachine == "" || this.auditRecord.actualBookValueMachine === null) {
            //     this.auditRecord.actualBookValueMachine = 0
            // }
            this.numberFields.forEach(element => {
                this.auditRecord[element] = this.getNumber(this.auditRecord[element])
            })

            var savedBooks = [...this.auditRecord.lotteryBookSpotCheckScanDataDTOList]
            
            savedBooks.forEach((book) => {

                switch (book.scanAuditlocation) {
                    case "0":
                        book.scannedFlag = false
                        book.location = null
                        book.status = "RECEIVED"
                        break
                    case "1":
                        book.scannedFlag = true
                        book.location = null
                        book.status = "RECEIVED"
                        break
                    case "2":
                        book.scannedFlag = false
                        book.location = "R"
                        book.status = "ONSALE"
                        break                                
                    case "3":
                        book.scannedFlag = true
                        book.location = "R"
                        book.status = "ONSALE"
                        break
                    case "4":
                        book.scannedFlag = false
                        book.location = "M"
                        book.status = "ONSALE"
                        break   
                    case "5":
                        book.scannedFlag = true
                        book.location = "M"
                        book.status = "ONSALE"
                        break     
                }
            })

            this.inSafeRecords = this.sortArrayByProp(savedBooks, 'pricePoint', 'asc').filter(item => item.status == "RECEIVED")
            this.insideSaleRecords = this.sortArrayByProp(savedBooks, 'pricePoint', 'asc').filter(item => item.status == "ONSALE" && item.location == "R")
            this.outsideSaleRecords = this.sortArrayByProp(savedBooks, 'pricePoint', 'asc').filter(item => item.status == "ONSALE" && item.location == "M")

            this.groupedInSafeBooks = await this.groupBooksByPrice(this.inSafeRecords,'insafe') 

            this.auditRecord.inventoryBookValueSafe = this.inSafeRecords.reduce((sum, item) => {
                            return sum + ((item.ticketCount - item.lastTicketSold) * item.pricePoint)
                            }, 0)
            this.auditRecord.inventoryBookValueRack = this.insideSaleRecords.reduce((sum, item) => {
                            return sum + ((item.ticketCount - item.lastTicketSold) * item.pricePoint)
                            }, 0)
            this.auditRecord.inventoryBookValueMachine = this.outsideSaleRecords.reduce((sum, item) => {
                            return sum + ((item.ticketCount - item.lastTicketSold) * item.pricePoint)
                            }, 0)


            this.auditRecord.bookBalance = this.auditRecord.lastMonthEndInstantLotteryInventory 
                                        + this.auditRecord.booksReceivedTotalValueBetweenAudits 
                                        - this.auditRecord.returnedTicketsTotalValueBetweenAudits
                                        - this.auditRecord.insideTotalValueBetweenAudits
                                        - this.auditRecord.lotteryMachineWithdraw

            this.auditRecord.inventory = this.auditRecord.inventoryBookValueSafe
                        + this.auditRecord.inventoryBookValueRack 
                        + this.auditRecord.inventoryBookValueMachine
        },

        clearScannedFlag(row) {
            var tableRow = {}
            if (row.status == "RECEIVED") {

                this.groupedInSafeBooks.forEach((group) => {
                    tableRow = group.books.find(x => x.id === row.bookId)
                    if (tableRow) {
                        tableRow.scannedFlag = false

                        var auditedIndex = this.auditRecord.lotteryBookSpotCheckScanDataDTOList.findIndex(y => y.bookId === row.bookId)
                        
                        if (auditedIndex > -1) {
                            this.auditRecord.lotteryBookSpotCheckScanDataDTOList.splice(auditedIndex, 1);
                        }
                        return
                    }
                })
                
            } else {
                
                tableRow = this.partialLotteryBooks.find(x => x.id === row.bookId)
                if (tableRow) {
                    tableRow.scannedFlag = false
                    var auditedIndex = this.auditRecord.lotteryBookSpotCheckScanDataDTOList.findIndex(y => y.bookId === row.bookId)
                    if (auditedIndex > -1) {
                        this.auditRecord.lotteryBookSpotCheckScanDataDTOList.splice(auditedIndex, 1);
                    }
                }
            }
        },

        setScannedFlag(rows, table, ticket) {
            rows.books.forEach((row) => {
                var bookIndex = this.auditRecord.lotteryBookSpotCheckScanDataDTOList.findIndex(x => x.bookId === row.id)
                if (bookIndex == -1) {
                    if (row.lastTicketSold === null) {
                        row.lastTicketSold = 0
                    }

                    if (ticket > row.lastTicketSold) {
                        row.ticketCountMissing = ticket - row.lastTicketSold
                    } else {
                        row.ticketCountMissing = 0
                    }

                    row.scannedFlag = true
                    var inSafeIndex = this.inSafeRecords.findIndex(x => x.id === row.id)
                    if (inSafeIndex > -1) {
                        this.inSafeRecords[inSafeIndex].scannedFlag = true
                    }

                    var rowCopy = Object.assign({}, row)
                    var index = this.auditRecord.lotteryBookSpotCheckScanDataDTOList.push(rowCopy)
                    this.auditRecord.ticketCountMissing = parseInt(this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].currentTicketNumber) -
                                                    parseInt(this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].lastTicketSold)   

                    this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].ticketCountMissing = this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].ticketCountMissing * 
                                                    this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].pricePoint

                    this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].bookId = row.id
                    this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].id = null
                    this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].scanAuditlocation = 1
                    this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].currentTicketNumber = row.currentTicketNumber
                    
                    this.groupedInSafeBooks = this.groupedInSafeBooks.slice(0)
                    this.setAuditedBooksCount(row)
                    return ["audited"]
                } else {
                    row.ticketCountMissing = 0
                    row.scannedFlag = false
                    row.scanAuditlocation = 0
                    this.auditRecord.lotteryBookSpotCheckScanDataDTOList.splice(bookIndex,1)
                    this.groupedInSafeBooks = this.groupedInSafeBooks.slice(0)
                    this.setAuditedBooksCount(row)
                    return ["caneled"]
                }
            })
        },

        setAuditedBooksCount(row) {
            var rowGroup
            if (row.books === undefined) {
                this.groupedInSafeBooks.forEach((group) => {
                    if (book === undefined) {
                        var book = group.books.find(x => ((x.gameNumber === row.gameNumber) && (x.bookNumber === row.bookNumber)))
                        if (book) {
                            rowGroup = group 
                        }
                    }                        
                })
            } else {
                rowGroup = row
            }
            
            rowGroup.auditedCount = 0
            rowGroup.books.forEach((book) => {
                if (book.scannedFlag) {
                    rowGroup.auditedCount++
                }
            })
            this.groupedInSafeBooks = this.groupedInSafeBooks.slice(0)
        },

        setScannedFlag1(row, table, ticket) {
            var bookIndex = this.auditRecord.lotteryBookSpotCheckScanDataDTOList.findIndex(x => x.bookId === row.id)
            
            if (bookIndex == -1) {
                row.scannedFlag = true
                if (row.lastTicketSold === null) {
                    row.lastTicketSold = 0
                }
                if (ticket > row.lastTicketSold) {
                    row.ticketCountMissing = ticket - row.lastTicketSold
                } else {
                    row.ticketCountMissing = 0
                }
                var rowCopy = Object.assign({}, row)
                var index = this.auditRecord.lotteryBookSpotCheckScanDataDTOList.push(rowCopy) 

                if (ticket > parseInt(this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].lastTicketSold)) {
                    this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].currentTicketNumber = parseInt(ticket)
                    this.soundBookEndingSequenceLess.play()
                    this.$buefy.toast.open({
                        message: "Lottery Book Found, but tickets missing.  Please verify.",
                                type: 'is-warning'
                    })                    
                } else {
                    this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].currentTicketNumber =  parseInt(this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].lastTicketSold)
                    this.soundBookFoundOnSale.play()

                    this.$buefy.toast.open({
                        message: "Instant Lottery Boook added",
                        type: 'is-success'
                    })
                }

                this.auditRecord.ticketCountMissing = parseInt(this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].currentTicketNumber) -
                                                parseInt(this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].lastTicketSold)   

                this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].ticketCountMissing = this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].ticketCountMissing * 
                                                this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].pricePoint

                this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].bookId = row.id
                this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].id = null
                this.auditRecord.lotteryBookSpotCheckScanDataDTOList[index - 1].scanAuditlocation = table
                switch (table) {
                    case "F":
                        this.groupedInSafeBooks = this.groupedInSafeBooks.slice(0)
                        break;
                    case "P":
                        this.partialLotteryBooks = this.partialLotteryBooks.slice(0)
                        break;
                    case "S":
                        this.inSafeRecords = this.inSafeRecords.slice(0)
                        break;
                    case "I":
                        this.insideSaleRecords = this.insideSaleRecords.slice(0)
                        break;
                    case "O":
                        this.outsideSaleRecords = this.outsideSaleRecords.slice(0)
                        break;                                        
                }

            } else {
                row.scannedFlag = false
                this.auditRecord.lotteryBookSpotCheckScanDataDTOList.splice(bookIndex, 1)
                this.$buefy.toast.open({
                    message: "Previously Scanned book removed." + row.scannedFlag,
                            type: 'is-warning'
                })
                // this.soundBookEndingSequenceLess.play()                  
            }

            this.auditRecord.calculatedBookValueSafe = this.auditRecord.lotteryBookSpotCheckScanDataDTOList
            
        },

        auditDayAdjustment(row) {
            return row.auditDayInsideSales 
                + row.auditDayOutsideSales
                + row.auditDayReturn
                - row.auditDayPurchase
        },

        totalAuditedAfterAuditDayAdjustment(row) {
            return row.totalAuditedValue + this.auditDayAdjustment(row)
        },

        totalVariance(row) {
           return  this.totalAuditedAfterAuditDayAdjustment(row) - this.getNumber(row.bookBalance)
        }

    },

    computed: {

    },

    watch: {
        dateRange: function() {
            this.fetchAuditList()
        },

    },      

    mounted() {
        var today = new Date()

        if (today.getDate() == 1) {
            today.setMonth(today.getMonth() - 1)
        }
        var firstDay=new Date(today.getFullYear(), today.getMonth(), 1)
        var lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0)
        this.dateRange=[firstDay, lastDay]
        
        this.fetchAuditList()
    }
}
</script>

<style>
.veecli-table {
    border-bottom: 0.5px solid gray;
    padding-bottom: 2.5em;
    height: 3.5em;
}

.veecli-table input[type=number]  {

    background-color: white;
    text-align: center;
    font-weight: 900;
    font-size-adjust: calc(500%);
    color: rgb(0, 0, 0);
    width: 80px;
    border-color: gold;
    border-width: 2px;
}

.veecli-table-heading {
    border-bottom: 0.5px;
    padding-bottom: 2.5em;
    height: 2em;
    background-color: #f2f2f2d7;
    font-weight: bold;
    font-size: larger;
    text-align: center;
    vertical-align: middle;
}

.lottery-seq-input input[type=number]  {

    background-color: white;
    text-align: center;
    font-weight: 900;
    font-size-adjust: calc(500%);
    color: rgb(0, 0, 0);
    width: 80px;
    border-color: gold;
    border-width: 2px;
}
.lottery-seq-input input[type=number][disabled] {
    background-color: whitesmoke;
    font-weight:normal;
    color: gray;
    border-color: gray;
    border-width: 1px;
}
</style>