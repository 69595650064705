<template>
<div class="box">
    <div class="has-background-grey-lighter has-text-centered has-text-default is-size-5 has-text-weight-bold">
        Scan Bar Code/Type all numbers<br>                            
    </div>
    <div>&nbsp;</div>
    <div v-show="inputMethod == 'Scan Book Bar Code'">
        <b-field>
            <b-input class="scanInput"
                v-model="scannedBookBarCode" 
                v-focus
                icon-pack="fas"
                icon="barcode"
                ref="scannedBookBarCode"
                @keyup.native.enter="processScannedInput"
                @focus="$event.target.select()"
                placeholder="Scan Instant Ticket Bar Code">
                >
            </b-input>
        </b-field> 
    </div>
    <div class="column">
        <b-notification 
            type="is-info is-light"
            :closable="isClosable" 
            aria-close-label="Close notification">
            Scan barcode like below 
            ({{ storeState.countryCode }}-{{ storeState.code }})
            {{ this.stateBarCodeFormat.Game }}/{{ this.stateBarCodeFormat.Book }}/{{ this.stateBarCodeFormat.Ticket }}
            
            <br> 
            <img src="/images/lottery-barcode-sample.png">
        </b-notification>
                                
    </div>
</div>    
</template>

<script>

// import axios from 'axios'

import UserStore from '../../../../../store/UserStore'

export default {
    props: {
        auditRecord: Object,
        groupedInSafeBooks: Array,
        inSafeBooks: Array,
        // partialLotteryBooks: Array,
    },

    data() {
        return {
            isClosable: false,
            storeState: UserStore.getters.getStoreState,
            stateBarCodeFormat: UserStore.getters.getStateBarCodeFormat,
            inputMethod: 'Scan Book Bar Code',
            scannedBookBarCode: "",
            gameNumber: "",
            bookNumber: "",
            ticketNumber: "",
            soundBookFoundOnSale: new Audio('/sounds/ScanBookFoundOnSale.mp3'),
            soundBookFoundInSafe: new Audio('/sounds/ScanBookFoundInSafe.mp3'),
            soundBookNotFoundOnSaleOrInSafe: new Audio('/sounds/ScanBookNotFoundOnSaleOrInSafe.mp3'),
            soundBookEndingSequenceLess: new Audio('/sounds/ScanBookEndingSequenceLess.mp3'),
        }
    },
    directives: {
        focus: {
            // directive definition
            inserted: function (el) {
                el.focus()
            }
        }
    },

    methods: {
        processScannedInput() {
            
            if (this.scannedBookBarCode != "") {

                this.scannedBookBarCode = this.scannedBookBarCode.replace('-', '')
                this.scannedBookBarCode = this.scannedBookBarCode.replace(' ', '')

                if (this.scannedBookBarCode.length < 13) {
                    this.soundBookEndingSequenceLess.play()
                    this.$buefy.toast.open({
                        message: "Invalid Scan " + this.scannedBookBarCode + '. Please try again.',
                                type: 'is-danger'
                    })
                    this.scannedBookBarCode = ""
                } else {
                    this.gameNumber = parseInt(this.scannedBookBarCode.substring(0,this.stateBarCodeFormat.Game))
                    this.bookNumber = this.scannedBookBarCode.substring(this.stateBarCodeFormat.Game,this.stateBarCodeFormat.Game+this.stateBarCodeFormat.Book)
                    this.ticketNumber = this.scannedBookBarCode.substring(this.stateBarCodeFormat.Game+this.stateBarCodeFormat.Book, this.stateBarCodeFormat.Game+this.stateBarCodeFormat.Book+this.stateBarCodeFormat.Ticket)

                    // var table = "F"

                    var row
                    this.groupedInSafeBooks.forEach((group) => {
                        if (row === undefined) {
                            row = group.books.find(x => ((x.gameNumber === this.gameNumber) && (x.bookNumber === this.bookNumber)))
                        }                        
                    })

                    if (row === undefined) {
                        this.$buefy.toast.open({
                            message: "Lottery Book not found in Safe, Inside Sale or Outside Sale Inventory " + this.scannedBookBarCode + '. Please make sure it is in the inventory and try again.',
                                    type: 'is-danger'
                        })
                        this.soundBookNotFoundOnSaleOrInSafe.play()                        
                    } else {
                        if (row.scannedFlag) {
                            this.$buefy.toast.open({
                            message: "Already Scanned/Audited book " + this.gameNumber + "-" + this.bookNumber + '.',
                                    type: 'is-warning'
                            })
                        } else {
                            this.$emit('set-scanned-flag', row , 'F', this.ticketNumber)
                        }
                    }
                   
                }
                this.scannedBookBarCode = ""
                this.$refs.scannedBookBarCode.focus()
            }
        },
     
       
    },   

}
</script>