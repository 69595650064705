<template>
<div class="section">

    <b-modal v-model="isShowPDFDialogLocal" scroll="keep" @close="closePDFDialog">
        <div class="card popupDialog">
            <div class="card-content">
                    <iframe id="pdf" class="pdfiFrame">
                        Your browser doesn't support iframes
                    </iframe> 
            </div>
        </div>
    </b-modal>        

</div>

</template>

<script>

import { PDFDocument, StandardFonts, PageSizes, degrees } from 'pdf-lib'
// import { PageSizes } from 'pdf-lib'
// import { degrees } from 'pdf-lib'
// import { rgb } from 'pdf-lib'
import moment from 'moment'
import UserStore from "../../../../../store/UserStore"

export default {
    props: {
        isShowPDFDialog: Boolean,
        inSafeBooks: Array,
        insideSaleRecords: Array,
        outsideSaleRecords: Array,
        auditRecord: Object,
        dateRange: Array,
        inventoryExpectedValue: Number,
        inSafeBooksScannedValue: Number
    },

    data() {
        return {
            sortedRecords: [],
            isShowPDFDialogLocal: false,
            totalBookValue: 0,
            totalBookCount: 0,
            totalSoldValue: 0,
            totalBalanceValue: 0,
            pageNumber: 1,
            dollarUSLocale: Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD'}),
            pngUrl: '/images/veecli-logo.png',
            pngImageBytes: "",
            pngImage: "",
            pngDims: "",
            pdfDoc: "",
            helveticaFont: "",
            page: "",
            price: 0,
            ticketSold : [],
            ticketNotSold: []     
        }

    },

    methods: {

        closePDFDialog() {
            this.$emit('close-pdf-dialog')
        },

        async createPdf() {

            this.pdfDoc = await PDFDocument.create()

            this.pngUrl = '/images/veecli-logo.png'
            this.pngImageBytes = await fetch(this.pngUrl).then((res) => res.arrayBuffer())

            this.pngImage = await this.pdfDoc.embedPng(this.pngImageBytes)
            this.pngDims = await this.pngImage.scale(0.08)
            this.helveticaFont = await this.pdfDoc.embedFont(StandardFonts.Helvetica)

            this.page = this.pdfDoc.addPage(PageSizes.Letter)
            // this.pageHeader(page, "Audit Summary")

            var lotteryCount = 0
            this.totalBookValue = 0
            this.totalBookCount = 0
            this.totalSoldValue = 0
            this.totalBalanceValue = 0               

            await this.pageHeader("Audit Summary")
            await this.auditSummary()   

            // Printing Scanned Books
            if (this.booksScanned.length > 0) {
                this.pageNumber++
                this.page = this.pdfDoc.addPage(PageSizes.Letter)

                await this.pageHeader("In Safe Inventory Audited")
                await this.columnHeading()
                var row = 640
                this.booksScanned.forEach( (lottery) => {

                    if (lotteryCount % 34 == 0 && lotteryCount > 0) {

                        this.pageFooter()
                        this.pageNumber++

                        this.page = this.pdfDoc.addPage(PageSizes.Letter)
                        this.pageHeader()
                        this.columnHeading()

                        // Set Line to start records
                        row = 670

                    }

                    this.price = this.dollarUSLocale.format(lottery.pricePoint)
                
                    this.ticketSold = lottery.lastTicketSold == null ? 0 : lottery.lastTicketSold
                    this.ticketNotSold = lottery.ticketCount - this.ticketSold

                    this.totalBookValue += lottery.ticketCount * lottery.pricePoint
                    this.totalBookCount ++
                    this.totalSoldValue += this.ticketSold * lottery.pricePoint
                    this.totalBalanceValue += this.ticketNotSold * lottery.pricePoint                

                    row = this.printLotteryBook(lottery, row)                 

                    row -= 16
                    lotteryCount++

                });

                this.pageTotal(row-3)
                this.pageFooter()
            }

            // End Printing Scanned Books

            // Printing In Safe Books not audited
            if (this.booksNotScanned.length > 0) {
                this.pageNumber++                
                this.totalBookValue = 0
                this.totalBookCount = 0
                this.totalSoldValue = 0
                this.totalBalanceValue = 0
                this.page = this.pdfDoc.addPage(PageSizes.Letter)

                var subhead = "In Safe Inventory Not Audited"
                await this.pageHeader(subhead)
                await this.columnHeading()
                row = 640
                await this.booksNotScanned.forEach( (lottery) => {

                    if (lotteryCount % 45 == 0 && lotteryCount > 0) {

                        this.pageFooter()
                        this.pageNumber++

                        this.page = this.pdfDoc.addPage(PageSizes.Letter)
                        this.pageHeader(subhead)
                        this.columnHeading()

                        // Set Line to start records
                        row = 640

                    }

                    this.price = this.dollarUSLocale.format(lottery.pricePoint)
                
                    this.ticketSold = lottery.lastTicketSold == null ? 0 : lottery.lastTicketSold
                    this.ticketNotSold = lottery.ticketCount - this.ticketSold

                    this.totalBookValue += lottery.ticketCount * lottery.pricePoint
                    this.totalBookCount ++
                    this.totalSoldValue += this.ticketSold * lottery.pricePoint
                    this.totalBalanceValue += this.ticketNotSold * lottery.pricePoint                

                    row = this.printLotteryBook(lottery, row)                

                    row -= 16
                    lotteryCount++

                });

                this.pageTotal(row-3)
                this.pageFooter()
            }           
            // End Printing In Safe Books not audited

            // Printing Inside Sale Books not audited
            if (this.insideSaleRecords.length > 0) {
                this.pageNumber++ 
                this.totalBookValue = 0
                this.totalBookCount = 0
                this.totalSoldValue = 0
                this.totalBalanceValue = 0
                lotteryCount = 0
                this.page = this.pdfDoc.addPage(PageSizes.Letter)

                subhead = "Inside Inventory Not Audited"
                await this.pageHeader(subhead)
                await this.columnHeading()
                row = 640
                await this.insideSaleRecords.forEach( (lottery) => {

                    if (lotteryCount % 45 == 0 && lotteryCount > 0) {

                        this.pageFooter()
                        this.pageNumber++

                        this.page = this.pdfDoc.addPage(PageSizes.Letter)
                        this.pageHeader(subhead)
                        this.columnHeading()

                        // Set Line to start records
                        row = 640

                    }

                    this.price = this.dollarUSLocale.format(lottery.pricePoint)
                
                    this.ticketSold = lottery.lastTicketSold == null ? 0 : lottery.lastTicketSold
                    this.ticketNotSold = lottery.ticketCount - this.ticketSold

                    this.totalBookValue += lottery.ticketCount * lottery.pricePoint
                    this.totalBookCount ++
                    this.totalSoldValue += this.ticketSold * lottery.pricePoint
                    this.totalBalanceValue += this.ticketNotSold * lottery.pricePoint                

                    row = this.printLotteryBook(lottery, row)                

                    row -= 16
                    lotteryCount++

                });

                this.pageTotal(row-3)
                this.pageFooter()
            }
            // End Printing Inside Sale Books not audited            

            // Printing Outside Sale Books not audited
            if (this.outsideSaleRecords.length > 0) {
                this.pageNumber++   
                this.totalBookValue = 0
                this.totalBookCount = 0
                this.totalSoldValue = 0
                this.totalBalanceValue = 0
                lotteryCount = 0
                this.page = this.pdfDoc.addPage(PageSizes.Letter)

                subhead = "Outside Inventory Not Audited"
                await this.pageHeader(subhead)
                await this.columnHeading()
                row = 640
                await this.outsideSaleRecords.forEach( (lottery) => {

                    if (lotteryCount % 30 == 0 && lotteryCount > 0) {

                        this.pageFooter()
                        this.pageNumber++

                        this.page = this.pdfDoc.addPage(PageSizes.Letter)
                        this.pageHeader(subhead)
                        this.columnHeading()

                        // Set Line to start records
                        row = 640

                    }

                    this.price = this.dollarUSLocale.format(lottery.pricePoint)
                
                    this.ticketSold = lottery.lastTicketSold == null ? 0 : lottery.lastTicketSold
                    this.ticketNotSold = lottery.ticketCount - this.ticketSold

                    this.totalBookValue += lottery.ticketCount * lottery.pricePoint
                    this.totalBookCount ++
                    this.totalSoldValue += this.ticketSold * lottery.pricePoint
                    this.totalBalanceValue += this.ticketNotSold * lottery.pricePoint                

                    row = this.printLotteryBook(lottery, row)                

                    row -= 16
                    lotteryCount++

                });

                this.pageTotal(row-3)
                this.pageFooter()
                // this.pageNumber++
            }
            // End Printing Outside Sale Books not audited 

            if (row < 50) {
                    this.pageFooter(this.pdfDoc)
                    this.pageNumber++

                    this.page = this.pdfDoc.addPage(PageSizes.Letter)
                    this.pageHeader("Done Know 2")

                    // Set Line to start records
                    row = 650                
            }
            // this.pageTotal(row-3)
            // this.pageFooter()

            const pdfDataUri = await this.pdfDoc.saveAsBase64({ dataUri: true });
            document.getElementById('pdf').src = pdfDataUri;
        },
       
        auditSummary() {

            var row = 670

                        this.page.drawText("LOTTERY BOOKS IN INVENTORY", { 
                x: 50, 
                y: row, 
                size: 13, 
                })

            row -= 30
            this.page.drawText("In Safe Scanned: ", { 
                x: 50, 
                y: row, 
                size: 11, 
                })
            
            textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.inSafeBooksScannedValue), 11);
            this.page.drawText(this.dollarUSLocale.format(this.inSafeBooksScannedValue), { 
                x: 240-textWidth, 
                y: row, 
                size: 11,
                TextAlignment: 2, 
                })

            row -= 20
            this.page.drawText("In Safe Not Scanned: ", { 
                x: 50, 
                y: row, 
                size: 11, 
                })
            
            textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.auditRecord.inventoryBookValueSafe - this.inSafeBooksScannedValue), 11);
            this.page.drawText(this.dollarUSLocale.format(this.auditRecord.inventoryBookValueSafe - this.inSafeBooksScannedValue), { 
                x: 240-textWidth, 
                y: row, 
                size: 11,
                TextAlignment: 2, 
                })
           
            this.page.drawLine({
                    start: { x: 50, y: row-5 },
                    end: { x: 300, y: row-5 },
                    thickness: 1,
                    opacity: 0.75,
                }) 

            row -= 20
            this.page.drawText("In Safe Inventory: ", { 
                x: 50, 
                y: row, 
                size: 11, 
                })

            textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.auditRecord.inventoryBookValueSafe), 11);
            this.page.drawText(this.dollarUSLocale.format(this.auditRecord.inventoryBookValueSafe), { 
                x: 300-textWidth, 
                y: row, 
                size: 11,
                TextAlignment: 2, 
                })

            row -= 20
            this.page.drawText("Inside Sale Inventory: ", { 
                x: 50, 
                y: row, 
                size: 11, 
                })

            textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.auditRecord.inventoryBookValueRack), 11);
            this.page.drawText(this.dollarUSLocale.format(this.auditRecord.inventoryBookValueRack), { 
                x: 300-textWidth, 
                y: row, 
                size: 11,
                TextAlignment: 2, 
                })

            row -= 20
            this.page.drawText("Outside Sale Inventory: ", { 
                x: 50, 
                y: row, 
                size: 11, 
                })

            textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.auditRecord.inventoryBookValueMachine), 11);
            this.page.drawText(this.dollarUSLocale.format(this.auditRecord.inventoryBookValueMachine), { 
                x: 300-textWidth, 
                y: row, 
                size: 11,
                TextAlignment: 2, 
                })

            this.page.drawLine({
                    start: { x: 50, y: row-5 },
                    end: { x: 300, y: row-5 },
                    thickness: 1,
                    opacity: 0.75,
                }) 
                
            row -= 30
            this.page.drawText("Total Inventory: ", { 
                x: 50, 
                y: row, 
                size: 11, 
                })

            var totalInventory = this.auditRecord.inventoryBookValueSafe + 
                                this.auditRecord.inventoryBookValueRack +
                                this.auditRecord.inventoryBookValueMachine

            textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(totalInventory), 11);
            this.page.drawText(this.dollarUSLocale.format(totalInventory), { 
                x: 300-textWidth, 
                y: row, 
                size: 11,
                TextAlignment: 2, 
                })

            this.page.drawLine({
                    start: { x: 50, y: row-5 },
                    end: { x: 300, y: row-5 },
                    thickness: 1,
                    opacity: 0.75,
                })

            row -= 40

            this.page.drawText("LOTTERY INVENTORY CHANGES", { 
                x: 50, 
                y: row, 
                size: 13, 
                })

            row -= 20   

            this.page.drawText("Opening Date: ", { 
                x: 50, 
                y: row, 
                size: 11, 
                })

            var textWidth = this.helveticaFont.widthOfTextAtSize(this.firstDayOfAuditMonth.format('MM/DD/YYYY'), 11);
            this.page.drawText(moment(this.firstDayOfAuditMonth).format('MM/DD/YYYY'), { 
                x: 300-textWidth, 
                y: row, 
                size: 11, 
                })


            row -= 20

            this.page.drawText("Opening Inventory: ", { 
                x: 50, 
                y: row, 
                size: 11, 
                })

            textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.auditRecord.lastMonthEndInstantLotteryInventory), 11);
            this.page.drawText(this.dollarUSLocale.format(this.auditRecord.lastMonthEndInstantLotteryInventory), { 
                x: 300-textWidth, 
                y: row, 
                size: 11,
                TextAlignment: 2, 
                })

            row -= 30
            this.page.drawText("Total New Tickets: ", { 
                x: 50, 
                y: row, 
                size: 11, 
                })

            textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.auditRecord.booksReceivedTotalValueBetweenAudits), 11);
            this.page.drawText(this.dollarUSLocale.format(this.auditRecord.booksReceivedTotalValueBetweenAudits), { 
                x: 300-textWidth, 
                y: row, 
                size: 11,
                TextAlignment: 2, 
                })

            row -= 20
            this.page.drawText("Total Inside Sales: ", { 
                x: 50, 
                y: row, 
                size: 11, 
                })

            textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.auditRecord.insideTotalValueBetweenAudits * -1), 11);
            this.page.drawText(this.dollarUSLocale.format(this.auditRecord.insideTotalValueBetweenAudits * -1), { 
                x: 300-textWidth, 
                y: row, 
                size: 11,
                TextAlignment: 2, 
                })

            row -= 20
            this.page.drawText("Total Outside Sales: ", { 
                x: 50, 
                y: row, 
                size: 11, 
                })

            var outsideSale = this.auditRecord.lotteryMachineWithdraw
            if (outsideSale != 0) {
                outsideSale *= -1
            }
            textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(outsideSale), 11);
            this.page.drawText(this.dollarUSLocale.format(outsideSale), { 
                x: 300-textWidth, 
                y: row, 
                size: 11,
                TextAlignment: 2, 
                })

            row -= 20
            this.page.drawText("Total Tickets Returned: ", { 
                x: 50, 
                y: row, 
                size: 11, 
                })

            var returnInv = this.auditRecord.returnedTicketsTotalValueBetweenAudits
            if (returnInv != 0) {
                returnInv *= -1
            }
            textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(returnInv), 11);
            this.page.drawText(this.dollarUSLocale.format(returnInv), { 
                x: 300-textWidth, 
                y: row, 
                size: 11,
                TextAlignment: 2, 
                })

            this.page.drawLine({
                    start: { x: 50, y: row-5 },
                    end: { x: 300, y: row-5 },
                    thickness: 1,
                    opacity: 0.75,
                }) 
                
            row -= 30
            this.page.drawText("Book Balance: ", { 
                x: 50, 
                y: row, 
                size: 11, 
                })

            textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.inventoryExpectedValue), 11);
            this.page.drawText(this.dollarUSLocale.format(this.inventoryExpectedValue), { 
                x: 300-textWidth, 
                y: row, 
                size: 11,
                TextAlignment: 2, 
                })

            this.page.drawLine({
                    start: { x: 50, y: row-5 },
                    end: { x: 300, y: row-5 },
                    thickness: 1,
                    opacity: 0.75,
                })

            // row -= 40
            row = 670
            this.page.drawText("Audit Date: " + moment(this.auditRecord.auditDate).format('MM/DD/YYYY'), { 
                x: 320, 
                y: row, 
                size: 11, 
                })

            row -= 20
            this.page.drawText("Auditor Name: " + this.auditRecord.auditerName, { 
                x: 320, 
                y: row, 
                size: 11, 
                })                                

            this.page.drawLine({
                start: { x: 320, y: row-5 },
                end: { x: 580, y: row-5 },
                thickness: 1,
                opacity: 0.75,
            }) 

            row -= 40

            this.page.drawText("LOTTERY AUDIT SUMMARY", { 
                x: 320, 
                y: row, 
                size: 13, 
                })

            row -= 30
            this.page.drawText("In Safe Total Value: ", { 
                x: 320, 
                y: row, 
                size: 11, 
                })

            textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.auditRecord.actualBookValueSafe), 11);
            this.page.drawText(this.dollarUSLocale.format(this.auditRecord.actualBookValueSafe), { 
                x: 520-textWidth, 
                y: row, 
                size: 11,
                TextAlignment: 2, 
                })

            row -= 20

            this.page.drawText("Inside Sale Total Value: ", { 
                x: 320, 
                y: row, 
                size: 11, 
                })

            textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.auditRecord.actualBookValueRack), 11);
            this.page.drawText(this.dollarUSLocale.format(this.auditRecord.actualBookValueRack), { 
                x: 520-textWidth, 
                y: row, 
                size: 11,
                TextAlignment: 2, 
                })

            row -= 20

            this.page.drawText("Outside Sale Total Value: ", { 
                x: 320, 
                y: row, 
                size: 11, 
                })

            textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.auditRecord.actualBookValueMachine), 11);
            this.page.drawText(this.dollarUSLocale.format(this.auditRecord.actualBookValueMachine), { 
                x: 520-textWidth, 
                y: row, 
                size: 11,
                TextAlignment: 2, 
                })

            this.page.drawLine({
                start: { x: 320, y: row-5 },
                end: { x: 580, y: row-5 },
                thickness: 1,
                opacity: 0.75,
            }) 
            row -= 30

            this.page.drawText("Total Audited: ", { 
                x: 320, 
                y: row, 
                size: 11, 
                })

            textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.auditRecord.totalAuditedValue), 11);
            this.page.drawText(this.dollarUSLocale.format(this.auditRecord.totalAuditedValue), { 
                x: 580-textWidth, 
                y: row, 
                size: 11,
                TextAlignment: 2, 
                }) 


            this.page.drawLine({
                start: { x: 320, y: row-5 },
                end: { x: 580, y: row-5 },
                thickness: 1,
                opacity: 0.75,
            }) 

            if (this.auditRecord.auditDayPurchase !== 0) {
                row -= 20

                this.page.drawText("Audit Day Purchase (-): ", { 
                    x: 320, 
                    y: row, 
                    size: 11, 
                    })

                textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.auditRecord.auditDayPurchase * -1), 11);
                this.page.drawText(this.dollarUSLocale.format(this.auditRecord.auditDayPurchase * -1), { 
                    x: 520-textWidth, 
                    y: row, 
                    size: 11,
                    TextAlignment: 2, 
                    })

            }            

            if (this.auditRecord.auditDayInsideSales !== 0) {
                row -= 20

                this.page.drawText("Audit Day Inside Sales (+): ", { 
                    x: 320, 
                    y: row, 
                    size: 11, 
                    })

                textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.auditRecord.auditDayInsideSales), 11);
                this.page.drawText(this.dollarUSLocale.format(this.auditRecord.auditDayInsideSales), { 
                    x: 520-textWidth, 
                    y: row, 
                    size: 11,
                    TextAlignment: 2, 
                    })

            }

            if (this.auditRecord.auditDayOutsideSales !== 0) {
                row -= 20

                this.page.drawText("Audit Day Outside Sales (+): ", { 
                    x: 320, 
                    y: row, 
                    size: 11, 
                    })

                textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.auditRecord.auditDayOutsideSales), 11);
                this.page.drawText(this.dollarUSLocale.format(this.auditRecord.auditDayOutsideSales), { 
                    x: 520-textWidth, 
                    y: row, 
                    size: 11,
                    TextAlignment: 2, 
                    })

            }

            if (this.auditRecord.auditDayReturn !== 0) {
                row -= 20

                this.page.drawText("Audit Day Return (+): ", { 
                    x: 320, 
                    y: row, 
                    size: 11, 
                    })

                textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.auditRecord.auditDayReturn), 11);
                this.page.drawText(this.dollarUSLocale.format(this.auditRecord.auditDayReturn), { 
                    x: 520-textWidth, 
                    y: row, 
                    size: 11,
                    TextAlignment: 2, 
                    })

            }

            if (this.isAuditDayAdjustment) {
                // row -= 20

                this.page.drawLine({
                    start: { x: 320, y: row-5 },
                    end: { x: 580, y: row-5 },
                    thickness: 1,
                    opacity: 0.75,
                })                 
                row -= 30
                this.page.drawText("Audit Day Adjustements: ", { 
                    x: 320, 
                    y: row, 
                    size: 11, 
                    })

                textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.auditDayAdjustment), 11);
                this.page.drawText(this.dollarUSLocale.format(this.auditDayAdjustment), { 
                    x: 580-textWidth, 
                    y: row, 
                    size: 11,
                    TextAlignment: 2, 
                    })

                
                this.page.drawLine({
                    start: { x: 320, y: row-5 },
                    end: { x: 580, y: row-5 },
                    thickness: 1,
                    opacity: 0.75,
                })
            }

            if (this.isAuditDayAdjustment) {
                row -= 30                 
                this.page.drawText("Inventory after Adjustments: ", { 
                    x: 320, 
                    y: row, 
                    size: 11, 
                    })

                textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.balanceAdjustedWithTodaysTransactions), 11);
                this.page.drawText(this.dollarUSLocale.format(this.balanceAdjustedWithTodaysTransactions), { 
                    x: 580-textWidth, 
                    y: row, 
                    size: 11,
                    TextAlignment: 2, 
                    })

                this.page.drawLine({
                    start: { x: 320, y: row-5 },
                    end: { x: 580, y: row-5 },
                    thickness: 1,
                    opacity: 0.75,
                }) 
            }

            row -= 30

            this.page.drawText("Variance: ", { 
                x: 320, 
                y: row, 
                size: 11, 
                })

            textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(this.varianceAfterAdjustment), 11);
            this.page.drawText(this.dollarUSLocale.format(this.varianceAfterAdjustment), { 
                x: 580-textWidth, 
                y: row, 
                size: 11,
                TextAlignment: 2, 
                }) 

            this.page.drawLine({
                start: { x: 320, y: row-5 },
                end: { x: 580, y: row-5 },
                thickness: 1,
                opacity: 0.75,
            }) 

            if (this.auditRecord.notes != null) {
                row -= 20
                this.page.drawText("Notes: " + this.auditRecord.notes, { 
                    x: 320, 
                    y: row, 
                    size: 11,
                    maxWidth: 500 
                    })                   
            }

              
        },

        printLotteryBook(lottery,row) {
            this.page.drawText(lottery.gameNumber.toString(), { 
                x: 20, 
                y: row, 
                size: 10, 
                })

            this.page.drawText(lottery.bookNumber.toString(), { 
                x: 60, 
                y: row, 
                size: 10, 
                })

            if (lottery.gameName === undefined) {
                gameName = ""
            } else {
                var gameName = lottery.gameName.toString().toLowerCase().substr(0, 25)
                gameName = gameName.replace(/\b\w/g, function(c) {
                                return c.toUpperCase();
                            })
            }

            this.page.drawText(gameName.substr(0,16), { 
                x: 120, 
                y: row, 
                size: 10, 
                })

            if (lottery.saleRackLoc != null) {
                this.page.drawText(lottery.saleRackLoc.toString(), { 
                    x: 250, 
                    y: row, 
                    size: 10, 
                    })
            }

            var textWidth = this.helveticaFont.widthOfTextAtSize(lottery.ticketCount.toString(), 10);

            this.page.drawText(lottery.ticketCount.toString(), { 
                x: 310 - textWidth, 
                y: row, 
                size: 10,
                TextAlignment: 2,
                })   

            textWidth = this.helveticaFont.widthOfTextAtSize(this.price, 10);

            this.page.drawText(this.price, { 
                x: 380 - textWidth, 
                y: row, 
                size: 10,
                TextAlignment: 2,
                })

            textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(lottery.totalValue.toString()), 10);

            this.page.drawText(this.dollarUSLocale.format(lottery.totalValue.toString()), { 
                x: 450 - textWidth, 
                y: row, 
                size: 10,
                TextAlignment: 2,
                })   

            textWidth = this.helveticaFont.widthOfTextAtSize(this.ticketSold.toString(), 10);

            this.page.drawText(this.ticketSold.toString(), { 
                x: 490 - textWidth, 
                y: row, 
                size: 10,
                TextAlignment: 2,
                })
                
            textWidth = this.helveticaFont.widthOfTextAtSize((lottery.ticketCount - this.ticketSold).toString(), 10);

            this.page.drawText((lottery.ticketCount - this.ticketSold).toString(), { 
                x: 530 - textWidth, 
                y: row, 
                size: 10,
                TextAlignment: 2,
                })  
                
            var invValue = (lottery.ticketCount - this.ticketSold) * lottery.pricePoint
            textWidth = this.helveticaFont.widthOfTextAtSize(this.dollarUSLocale.format(invValue), 10);
            this.page.drawText(this.dollarUSLocale.format(invValue), { 
                x: 590 - textWidth, 
                y: row, 
                size: 10,
                TextAlignment: 2,
                })                  

            row -= 3
            this.page.drawLine({
                start: { x: 10, y: row },
                end: { x: 600, y: row },
                thickness: .5,
                opacity: 0.20,
            }) 

            return row
        },        

        pageHeader(subhead) {

            var storeName = UserStore.getters.getActiveStore.storeName
            var textWidth = this.helveticaFont.widthOfTextAtSize(storeName, 15);
            var row = 750
            this.page.drawText(storeName, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 18, 
                })

            row = row-25
            var reportHeading = "Lottery Audit - " + moment(this.auditRecord.auditDate).format('MM/DD/YYYY')
            textWidth = this.helveticaFont.widthOfTextAtSize(reportHeading, 13);
            this.page.drawText(reportHeading, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 16, 
                })
                
            row = row-25
            // var reportHeading = subhead
            textWidth = this.helveticaFont.widthOfTextAtSize(subhead, 13);
            this.page.drawText(subhead, { 
                x: 300 - (textWidth/2), 
                y: row, 
                size: 16, 
                })                  
                
        },

        columnHeading() {

            var row = 670

            // Print Column Heading
            this.page.drawText('Game', { 
                x: 20, 
                y: row, 
                size: 11, 
                })

            this.page.drawText('Book', { 
                x: 60, 
                y: row, 
                size: 11, 
                })

            this.page.drawText('Name', { 
                x: 120, 
                y: row, 
                size: 11, 
                })
  
            this.page.drawText('Bin', { 
                x: 250, 
                y: row, 
                size: 11, 
                })

            this.page.drawText('Tickets', { 
                x: 280, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            this.page.drawText('Price', { 
                x: 355, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            this.page.drawText('Total', { 
                x: 420, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            this.page.drawText('Sold', { 
                x: 470, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            this.page.drawText('Inventory', { 
                x: 500, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            this.page.drawText('Value', { 
                x: 560, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            // this.page.drawText('Missing $', { 
            //     x: 550, 
            //     y: row, 
            //     size: 11,
            //     TextAlignment: 2
            //     })

            row -= 4
            this.page.drawLine({
                start: { x: 10, y: row },
                end: { x: 600, y: row },
                thickness: 1,
                opacity: 0.75,
            })
        },

        async pageFooter() {

            let timeNow = Date()
            timeNow = moment(timeNow).format('MM/DD/YYYY hh:mm a')

            this.page.drawText(timeNow, { 
                x: 20, 
                y: 20, 
                size: 10,
                TextAlignment: 2
                })

            this.page.drawImage(this.pngImage, {
                x: 300,
                y: 18,
                width: this.pngDims.width,
                height: this.pngDims.height,
                rotate: degrees(0),
                // opacity: 0.75,
            })
            this.page.drawText(" VEECLi.com", { 
                x: 308, 
                y: 20, 
                size: 10,
                TextAlignment: 2
                })             

            this.page.drawText('Page ' + this.pageNumber, { 
                x: 540, 
                y: 20, 
                size: 10,
                TextAlignment: 2
                })
            
        },
        
        pageTotal(row) {
            console.log("Row",row)
            row -= 30

            this.page.drawLine({
                start: { x: 10, y: row },
                end: { x: 600, y: row },
                thickness: 1,
                opacity: 0.75,
            })  
            row -= 15

            this.page.drawText('Book Count: ' + this.totalBookCount.toString(), { 
                x: 30, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            this.page.drawText('Book Value: ' + this.dollarUSLocale.format(this.totalBookValue), { 
                x: 140, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            this.page.drawText('Sold Value: ' + this.dollarUSLocale.format(this.totalSoldValue), { 
                x: 290, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            this.page.drawText('Balance Value: ' + this.dollarUSLocale.format(this.totalBalanceValue), { 
                x: 420, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            row -= 10
            this.page.drawLine({
                start: { x: 10, y: row },
                end: { x: 600, y: row },
                thickness: 1,
                opacity: 0.75,
            })  
    
        },

        loadData() {
            this.sortedRecords = this.auditRecord.lotteryBookSpotCheckScanDataDTOList.sort(function(a, b){     // => [ 4, 33, 222, 1111 ]
                return (a.gameNumber+a.bookNumber)-(b.gameNumber+b.bookNumber)
            })
            this.isShowPDFDialogLocal = true
            this.createPdf(this)
        }
    },
  
    computed: {
        totalAuditedValue() {
            return parseFloat(this.auditRecord.actualBookValueSafe) + 
                parseFloat(this.auditRecord.actualBookValueRack) + 
                parseFloat(this.auditRecord.actualBookValueMachine) -
                parseFloat(this.auditRecord.missingBookValue)
        },

        bookBalanceExpected() {
            return parseFloat(this.previousBookValueTotal) +
                parseFloat(this.auditRecord.booksReceivedTotalValueBetweenAudits) -
                parseFloat(this.auditRecord.insideTotalValueBetweenAudits) -
                parseFloat(this.auditRecord.outsideTotalValueBetweenAudits) -
                parseFloat(this.auditRecord.returnedTicketsTotalValueBetweenAudits) 
        },        

        auditVariance() {
            return parseFloat(this.totalAuditedValue) -
                    parseFloat(this.bookBalanceExpected)
                 

        },

        previousBookValueTotal() {
            return parseFloat(this.auditRecord.previousBookValueSafe) + 
                parseFloat(this.auditRecord.previousBookValueRack) + 
                parseFloat(this.auditRecord.previousBookValueMachine)
        },
        
        booksScanned() {
            return this.inSafeBooks.filter((book) => {
                return book.scannedFlag
            })
        },

        booksNotScanned() {
            return this.inSafeBooks.filter((book) => {
                return !book.scannedFlag
            })
        },

        firstDayOfAuditMonth() {
            return moment(this.auditRecord.auditDate).startOf('month')
        },

        auditDayAdjustment() {
            return this.getNumber(this.auditRecord.auditDayOutsideSales)
                + this.getNumber(this.auditRecord.auditDayInsideSales)
                + this.getNumber(this.auditRecord.auditDayReturn)
                - this.getNumber(this.auditRecord.auditDayPurchase)
        },

        balanceAdjustedWithTodaysTransactions() {
            return this.auditRecord.totalAuditedValue 
                + this.auditDayAdjustment
        },

        varianceAfterAdjustment() {
            return this.balanceAdjustedWithTodaysTransactions
                - this.inventoryExpectedValue 

        },
        
        isAuditDayAdjustment() {
            return this.auditRecord.auditDayOutsideSales != 0.00 
                || this.auditRecord.auditDayInsideSales != 0.00
                || this.auditRecord.auditDayReturn != 0.00
                || this.auditRecord.auditDayPurchase != 0.00
        }
    },

}
</script>


<style scoped>

.popupDialog {
    /* position:absolute; */
    width:100%;
    height: 100%; 
    max-height: 1000px;
    min-height: 900px;     
}

.pdfiFrame {
    position:absolute; 
    top:0; 
    left:0; 
    bottom:0; 
    /* right:0;  */
    width:100%; 
    height:100%; 
    /* border:none; */
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
    /* padding:0;  */
    overflow:hidden; 
    /* z-index:999999;    */
}
div{
    height: 100%;
}
</style>