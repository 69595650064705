<template>
    <!-- <div class="modal" v-bind:class="{ 'is-active': isShowAuditDialog }">
        <div class="modal-background"></div> -->
    <b-modal
        v-model="isShowAuditDialog"
        has-modal-card
        full-screen
        :can-cancel="false"
        @close="closeDialog"
        >
        <div class="modal-card scrollable">
        <header class="modal-card-head">
            <p class="modal-card-title">Instant Lottery Audit Details  - {{ auditRecord.auditDate | formatDate}}
            </p>&nbsp;&nbsp;
            <div class="level-right has-text-centered">
                <b-button  title="Print" type="is-dark" outlined class="is-small is-hidden-mobile" @click="togglePDFDialog">
                    <i class="fas fa-print"></i>
                </b-button>
            </div>
            &nbsp;&nbsp; &nbsp;
            <button class="delete is-danger" type="button" @click="closeDialog">Cancel</button>
        </header>
        <div class="modal-card-body">
            <article>                
            <b-tabs size="is-medium" position="is-left" type="is-boxed">
                
                <b-tab-item >
                    <template #header>
                        <span> <i class="fas fa-chart-bar"></i> Summary</span>
                    </template>
                    <article>
                    <div class="columns bottom-bordered-row">
                        <div class="column is-5">
                            <div class="box">
                            <div class="has-text-centered has-background-grey-lighter">
                                LOTTERY INVENTORY CHANGES
                            </div>
                            <br>
                            <b-field>
                                <div class="columns is-mobile veecli-table">
                                    <div class="column is-8">
                                        Opening Balance on {{ firstDayOfAuditMonth | formatDate }}
                                    </div>
                                    <div class="column has-text-right is-4">
                                        {{ auditRecord.lastMonthEndInstantLotteryInventory  | formatCurrency}}
                                    </div>
                                </div>
                            </b-field>

                            <b-field>
                                <div class="columns is-mobile veecli-table">
                                    <div class="column is-8">
                                        Total Purchase in {{ auditMonthName }}
                                        <span v-if="auditRecord.safeScanFlag && isEditable"></span>                                                    
                                    </div>
                                    <div class="column has-text-right is-4">
                                        {{ auditRecord.booksReceivedTotalValueBetweenAudits  | formatCurrency}}
                                    </div>
                                </div>
                            </b-field>

                            <b-field>
                                <div class="columns is-mobile veecli-table">
                                    <div class="column is-8">
                                        Total Returns in {{ auditMonthName }}
                                        <span v-if="auditRecord.safeScanFlag && isEditable"></span>                                                    
                                    </div>
                                    <div class="column has-text-right is-4">
                                        {{ (auditRecord.returnedTicketsTotalValueBetweenAudits ? auditRecord.returnedTicketsTotalValueBetweenAudits * -1 : 0)| formatCurrency}}
                                    </div>
                                </div>
                            </b-field> 
     
                            <b-field>
                                <div class="columns is-mobile veecli-table">
                                    <div class="column is-8">
                                        Total Inside Sales in {{ auditMonthName }}
                                        <span v-if="auditRecord.safeScanFlag && isEditable"></span>                                                    
                                    </div>
                                    <div class="column has-text-right is-4">
                                        {{ (auditRecord.insideTotalValueBetweenAudits ? auditRecord.insideTotalValueBetweenAudits * -1 : 0)  | formatCurrency }}
                                    </div>
                                </div>
                            </b-field>

                            <b-field>
                                <div class="columns is-mobile veecli-table">
                                    <div class="column is-8">
                                        Total Outside Sales in {{ auditMonthName }}
                                        <span v-if="auditRecord.safeScanFlag && isEditable"></span>                                                    
                                    </div>
                                    <div class="column has-text-right is-4">
                                                                                    
                                        {{ (auditRecord.lotteryMachineWithdraw ? auditRecord.lotteryMachineWithdraw * -1 : 0) | formatCurrency}}

                                    </div>
                                </div>
                            </b-field>

                            <br>
                            <b-field>
                                <div class="columns is-mobile has-background-white-ter">
                                    <div class="column is-8">
                                        Inventory Expected Value {{ auditRecord.auditDate | formatDate }}
                                    </div>
                                    <div class="column has-text-right is-4">
                                        {{ inventoryExpectedValue  | formatCurrency}}
                                    </div>
                                </div>
                            </b-field> 

                            <br>
                            
                            <b-field label="Auditor Name" label-for="auditerName">
                                <VeeInput
                                    name="auditerName"
                                    type="text"
                                    v-model="auditRecord.auditerName"
                                    :value="auditRecord.auditerName"
                                    width="15em"
                                    :required="true"
                                    :editable="isEditable"
                                    >
                                </VeeInput>
                            </b-field>
                            <b-field label="Auditor Notes" label-for="auditNotes">
                                <VeeInput
                                    name="auditNotes"
                                    type="textarea"
                                    v-model="auditRecord.notes"
                                    :value="auditRecord.notes"
                                    :editable="isEditable"
                                    width="90%"
                                    >
                                </VeeInput>
                            </b-field>                          
                        </div>
                        </div>
                        <div class="column is-7">
                            <div class="box">
                            <div class="has-text-centered has-background-grey-lighter">
                                LOTTERY AUDIT SUMMARY
                            </div>
                            <br>
                            <b-field>
                                <div class="columns is-mobile veecli-table">
                                    <div class="column is-8">
                                        Audited Inventory - Safe - {{ auditRecord.auditDate | formatDate }}
                                        <br>
                                        <div class="has-text-grey-light is-hidden-touch">
                                            (Inventory {{ auditRecord.inventoryBookValueSafe | formatCurrency }}
                                            Audited {{ inSafeBooksScannedValue  | formatCurrency}})
                                        </div>                                                    
                                    </div>
                                    <div class="column has-text-right is-4">                                            
                                        <VeeInput 
                                            v-model="auditRecord.actualBookValueSafe"
                                            type="currency"
                                            name="actualBookValueSafe"
                                            :value="auditRecord.actualBookValueSafe"
                                            :countryCurrencyCode="countryCurrencyCode"
                                            :editable="isEditable"
                                            @input="reCalculate"
                                            />
                                    </div>
                                </div>
                            </b-field>                                                            
                            <b-field>
                                <div class="columns is-mobile veecli-table">
                                    <div class="column is-8">
                                        Audited Inventory - Inside - {{ auditRecord.auditDate | formatDate }}
                                        <br>
                                        <div class="has-text-grey-light is-hidden-touch">
                                            (Inventory {{ auditRecord.inventoryBookValueRack | formatCurrency }}) 
                                        </div>
                                                                                            
                                    </div>
                                    <div class="column has-text-right is-4">
                                        <VeeInput 
                                            type="currency"
                                            v-model="auditRecord.actualBookValueRack"
                                            name="actualBookValueRack"
                                            :value="auditRecord.actualBookValueRack"
                                            :countryCurrencyCode="countryCurrencyCode"
                                            :editable="isEditable"
                                            @input="reCalculate"
                                            />
                                    </div>
                                </div>
                            </b-field> 
                            <b-field>
                                <div class="columns is-mobile veecli-table">
                                    <div class="column is-8">
                                        Audited Inventory - Outside - {{ auditRecord.auditDate | formatDate }}
                                        <br>
                                        <div class="has-text-grey-light is-hidden-touch">
                                            (Inventory {{ auditRecord.inventoryBookValueMachine | formatCurrency }})
                                        </div>
                                    </div>
                                    <div class="column has-text-right is-4">
                                        <VeeInput 
                                            type="currency" 
                                            v-model="auditRecord.actualBookValueMachine"
                                            name="actualBookValueMachine"
                                            :value="auditRecord.actualBookValueMachine"
                                            :countryCurrencyCode="countryCurrencyCode"
                                            :editable="isEditable"
                                            @input="reCalculate"
                                            />
                                    </div>
                                </div>
                            </b-field>                                
                            <br>
                            <div class="columns is-mobile has-background-white-ter">
                                <div class="column is-8">
                                    Total Audited Amount:
                                    <div class="has-text-grey-light is-hidden-touch">
                                        (Total in Inventory {{ auditRecord.inventory| formatCurrency }}.    
                                        Variance {{ auditRecord.inventory - inventoryExpectedValue  | formatCurrency}})                
                                    </div>
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ auditRecord.totalAuditedValue |formatCurrency }}
                                </div>                                
                            </div>

                            <b-field>
                                <div class="columns is-mobile veecli-table">
                                    <div class="column is-8">
                                        Audit Day Purchase (-)
                                    </div>
                                    <div class="column has-text-right is-4">
                                        <VeeInput 
                                            type="currency" 
                                            v-model="auditRecord.auditDayPurchase"
                                            name="auditDayPurchase"
                                            :value="auditRecord.auditDayPurchase"
                                            :countryCurrencyCode="countryCurrencyCode"
                                            :editable="isEditable"
                                            @input="reCalculate"
                                            />
                                    </div>
                                </div>
                            </b-field>

                            <div class="columns is-mobile veecli-table">
                                <div class="column is-8">
                                    Audit Day Inside Sales (+)
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ auditRecord.auditDayInsideSales |formatCurrency }}
                                </div>
                            </div>

                            <b-field>
                                <div class="columns is-mobile veecli-table">
                                    <div class="column is-8">
                                        Audit Day Outside Sales (+)
                                    </div>
                                    <div class="column has-text-right is-4">
                                        <VeeInput 
                                            type="currency" 
                                            v-model="auditRecord.auditDayOutsideSales"
                                            name="auditDayOutsideSales"
                                            :value="auditRecord.auditDayOutsideSales"
                                            :countryCurrencyCode="countryCurrencyCode"
                                            :editable="isEditable"
                                            @input="reCalculate"
                                            />
                                    </div>
                                </div>
                            </b-field>
                            
                            <b-field>
                                <div class="columns is-mobile veecli-table">
                                    <div class="column is-8">
                                        Audited Day Return (+)
                                    </div>
                                    <div class="column has-text-right is-4">
                                        <VeeInput 
                                            type="currency" 
                                            v-model="auditRecord.auditDayReturn"
                                            name="auditDayReturn"
                                            :value="auditRecord.auditDayReturn"
                                            :countryCurrencyCode="countryCurrencyCode"
                                            :editable="isEditable"
                                            @input="reCalculate"
                                            />
                                    </div>
                                </div>
                            </b-field>                            
                            
                            <div class="columns is-mobile has-background-white-ter">
                                <div class="column is-8">
                                    After Audit Day Adjustements:
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ balanceAdjustedWithTodaysTransactions |formatCurrency }}
                                </div>
                            </div>

                            <div class="columns is-mobile veecli-table">
                                <div class="column is-8">
                                    Over/Short:
                                </div>
                                <div class="column has-text-right is-4">
                                    {{ actualInventoryVariance |formatCurrency }}
                                </div>
                            </div> 
                            
                        </div>
                        </div>                        
                    </div>
                    </article>
                </b-tab-item>
                               
                <b-tab-item>
                    <template #header>
                        <span> <i class="fas fa-book"></i> In Safe Books 
                        <b-tag class="is-success" rounded> {{ inSafeBooksScanned.length }} </b-tag>
                        <b-tag class="is-warning" rounded> {{ inSafeBooks.length - inSafeBooksScanned.length }} </b-tag>
                        </span>
                    </template>
                       Inventory {{ auditRecord.inventoryBookValueSafe | formatCurrency }}
                                            Audited {{ inSafeBooksScannedValue  | formatCurrency}}
                        &nbsp;&nbsp; 
                        <span v-if="checkedFullBookRows.length > 0">&nbsp;&nbsp;<b-button class="is-primary is-small" @click="updateBooksScannedFlag">Selected Books Audited</b-button> </span> 
                        
                        <article class="box">
                            <div class="columns">
                                <div class="column is-9">
                                    <b-table 
                                        :data='groupedInSafeBooks'
                                        :show-detail-icon="showDetailIcon"
                                        :row-class="partialRowClass"
                                        detailed
                                        detail-key="pricePoint"
                                        >
                                        <template>

                                        <b-table-column class="is-2 has-text-right" v-slot="props">
                                            <b-tooltip type="is-success" v-show="props.row.auditedCount == props.row.count" position="is-right" label="All books in this group have been audited." dashed>
                                                <b-button :disabled="!isEditable" class="is-primary is-small has-text-success" @click="setFullGroupScannedFlag(props.row)" outlined rounded>
                                                    ⬤ 
                                                </b-button>
                                            </b-tooltip>
                                            <b-tooltip type="is-warning" v-show="props.row.auditedCount < props.row.count && props.row.auditedCount > 0" position="is-right" label="Some books in this group have not been audited." dashed>
                                                <b-button :disabled="!isEditable" class="is-warning has-text-default is-small" @click="setFullGroupScannedFlag(props.row)" rounded>
                                                    <!-- <i class="fas fa-barcode"></i> -->
                                                    {{ props.row.auditedCount }}/{{ props.row.count }}
                                                </b-button>
                                            </b-tooltip>
                                            <b-tooltip type="is-danger"  v-show="props.row.auditedCount == 0" position="is-right" label="No Books in this group have been audited." dashed>
                                                <b-button :disabled="!isEditable"  class="is-danger has-text-default is-small" @click="setFullGroupScannedFlag(props.row)" outlined rounded>
                                                    <i class="fas fa-barcode"></i>
                                                </b-button>
                                            </b-tooltip> 
                                            
                                        </b-table-column>
                                        <!-- <b-table-column numeric label="Manual Book Count" v-slot="props">
                                            <b-input 
                                                class="manual-count-input"
                                                v-if="isEditable"
                                                custom-class="has-text-right"
                                                name="manualBookCount" 
                                                id="manualBookCount" 
                                                type="text"  
                                                required 
                                                icon-pack="fas" 
                                                icon="fa-ticket"
                                                @focus="$event.target.select()" 
                                                @input="reCalculate"
                                                v-model="props.row.manualBookCount" 
                                                >
                                            </b-input>
                                            <div v-else>
                                                {{ props.row.manualBookCount |formatCurrency}}
                                            </div>


                                                {{ props.row.manualBookCount }}
                                        </b-table-column> -->
                                        <b-table-column numeric label="Ticket Price" v-slot="props">
                                                {{ props.row.pricePoint | formatCurrency }}
                                        </b-table-column>

                                        <b-table-column numeric label="Audited Books" v-slot="props">
                                                {{ props.row.auditedCount }}
                                        </b-table-column>
                                        <b-table-column numeric label="Books in Inventory" v-slot="props">
                                                {{ props.row.count }}
                                        </b-table-column>
                                        <b-table-column numeric label="Partial Books" v-slot="props">
                                                <span>{{ props.row.partialBookCount }}</span>
                                        </b-table-column>                                         
                                        <b-table-column numeric label="Tickets" v-slot="props">
                                                {{ props.row.ticketCount }}
                                        </b-table-column>
                                        <b-table-column numeric label="Book Value" v-slot="props">
                                                {{ (props.row.pricePoint *  props.row.ticketCount) | formatCurrency }}
                                        </b-table-column>                               
                                        <b-table-column numeric label="Inventory Value" v-slot="props">
                                                {{ (props.row.pricePoint *  props.row.ticketCount * props.row.count) | formatCurrency }}
                                        </b-table-column>
                                        </template>
                                        <template slot="detail" slot-scope="props">
                                            <b-table :data="props.row.books"
                                                    :checkable="fullBookCheckable"
                                                    :checked-rows.sync="checkedFullBookRows"
                                                    :row-class="fullRowClass"
                                                    @rows-checked="updateBooksScannedFlag"
                                                >
                                                <template>
                                                    
                                                    <b-table-column class="is-2 has-text-right" v-slot="props">
                                                        <b-tooltip position="is-right" label="Click on button to mark as Audited" dashed>
                                                            <b-button :disabled="!isEditable" v-show="!props.row.scannedFlag" class="is-primary has-text-default is-small" @click="setFullBookScannedFlag(props.row)" outlined rounded>
                                                                <i class="fas fa-barcode"></i>
                                                            </b-button>
                                                        </b-tooltip>
                                                        <b-tooltip type="is-success" position="is-right" label="Instant Lottery Book Audited" dashed>    
                                                            <b-button :disabled="!isEditable" v-show="props.row.scannedFlag" class="is-primary is-small has-text-success" @click="setFullBookScannedFlag(props.row)" outlined rounded>
                                                                ⬤
                                                            </b-button>            
                                                        </b-tooltip> 
                                                    </b-table-column>

                                                    <b-table-column label="Loc" class="is-2 has-text-center" v-slot="props">
                                                        <b-tooltip position="is-right" dashed>  
                                                            <b-icon
                                                                pack="fas"
                                                                :icon="statusIcon(props.row)"
                                                                size="is-small">
                                                            </b-icon>
                                                            <template v-slot:content>
                                                                {{ statusIconTitle(props.row) }}
                                                            </template>
                                                        </b-tooltip>
                                                    </b-table-column>

                                                    <b-table-column label="Game-Book" v-slot="props" >
                                                        {{ props.row.gameNumber }}-{{ props.row.bookNumber }}                                                
                                                    </b-table-column>
                                                    <b-table-column numeric label="Tickets in book" v-slot="props">
                                                            {{ props.row.ticketCount }}
                                                    </b-table-column>
                                                    <b-table-column numeric label="Tickets Sold" v-slot="props">
                                                            {{ props.row.lastTicketSold }}
                                                    </b-table-column>                                            
                                                    <b-table-column numeric label="Inventory Tickets" v-slot="props">
                                                            {{ props.row.ticketCount - props.row.lastTicketSold }}
                                                    </b-table-column>
                                                    <b-table-column numeric label="Inventory Value" v-slot="props">
                                                            {{ ((props.row.ticketCount - (props.row.lastTicketSold ? props.row.lastTicketSold : 0)) * props.row.pricePoint) | formatCurrency }}
                                                    </b-table-column>
                                                </template>
                                            </b-table>
                                        </template>
                                    </b-table>
                                </div>
                                <div class="column is-3">
                                    <div v-if="isEditable">
                                        <lottery-audit-scan-input
                                            
                                            :groupedInSafeBooks="groupedInSafeBooks"
                                            @focus="$event.target.select()"
                                            @set-scanned-flag="setFullBookScannedFlag"
                                        >
                                        </lottery-audit-scan-input>
                                    </div>
                                </div>
                            </div>
                        </article>
                </b-tab-item>                
            </b-tabs>
            </article>
        </div>
        <footer class="modal-card-foot">
        <button class="button is-success" 
                type="button" 
                @click="submitForm"
                v-if="isEditable" 
                :disabled='isSaveDisabled'>Save</button>
        <button class="button" type="button" @click="closeDialog">Cancel</button>
        </footer>
        </div>

        <lottery-audit-pdf
            ref="pdfReport"
            :isShowPDFDialog="isShowPDFDialog"
            :inSafeBooks="inSafeBooks"
            :insideSaleRecords="insideSaleRecords"
            :outsideSaleRecords="outsideSaleRecords"
            :auditRecord="auditRecord"
            :inSafeBooksScannedValue="inSafeBooksScannedValue"
            :inventoryExpectedValue="inventoryExpectedValue"
            @close-pdf-dialog="togglePDFDialog"
        >

        </lottery-audit-pdf>
    </b-modal>
</template>

<script>

import moment from 'moment'

import LotteryAuditScanInput from './LotteryAuditScanInput'
import LotteryAuditPdf from './LotteryAuditPDF'
import VeeInput from '../../../../../components/app/VeeInput.vue'

export default {

    components: {
        LotteryAuditScanInput,
        LotteryAuditPdf,
        VeeInput
    },

    props: {
        isShowAuditDialog: Boolean,
        canCancelDialog: Boolean,
        auditRecord: Object,
        groupedInSafeBooks: Array,
        insideSaleRecords: Array,
        outsideSaleRecords: Array,
        allLotteryBooks: Array,
        auditDaySales: Number,
        numberFields: Array,
        isEditable: Boolean,
        countryCurrencyCode: String
    },

    metaInfo: {
        title: 'Lottery Audit Details'
    },


    data() {
        return {
            isTableStickyHeader: true,
            isPaginated: true,
            perPage: 20,
            tableHeight: 500,
            falseValue: false,
            isLoading: false,
            isFullPage: false,
            scannedBookBarCode: "",
            hidePartialScanned: true,
            hideFullScanned: true,
            partialBookCheckable: true,
            fullBookCheckable: false,
            checkedFullBookRows: [],
            checkedPartialSoldBookRows: [],
            showNumberControls: false,
            showDetailIcon: true,
            isShowPDFDialog: false,
        }
    },

    methods: {
        closeDialog() {

            if (this.isEditable) {
                // this.soundBookEndingSequenceLess.play()
                this.$buefy.dialog.confirm({
                    title: 'Leaving without saving!',
                    message: 'Are you sure you want to discard all changes and leave?',
                    confirmText: 'Discard Changes',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$emit('hide-dialog')
                    }
                })
            } else {
                this.$emit('hide-dialog')
            }
            
        },

        async clearScannedFlag(row) {
            this.$emit('clear-scanned-flag',row)
            await this.reCalculate()
        },


        togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                this.$refs.pdfReport.loadData()
            }
        },

        submitForm() {
            this.auditRecord.bookBalance = this.inventoryExpectedValue
            this.$emit('save-record')
        },

        rowsPartialSoldBooksChecked(rows) {
            this.checkedPartialSoldBookRows = rows
        },

        async setScannedFlag(row,table,ticket) {
            this.$emit('set-scanned-flag',row,table,ticket)
            await this.reCalculate()
        },

        async updateBooksScannedFlag() {
            var item = {}
            item.books = []
            this.checkedFullBookRows.forEach(row => {
                item.books.push(row)
            })
            this.setFullGroupScannedFlag(item)
        },

        async setFullBookScannedFlag(row) {
            var item = {}
            item.books = []
            item.books.push(row)
            await this.$emit('set-scanned-flag',item,"F",0)
            await this.reCalculate()
            this.$buefy.toast.open({
                message: "Audit processed for " + item.books.length +" record(s).",
                        type: 'is-warning'
            })
        },

        async setFullGroupScannedFlag(row) {
            await this.$emit('set-scanned-flag',row,"F",0)
            await this.reCalculate()
            this.$buefy.toast.open({
                message: "Audit processed for " + row.books.length +" record(s).",
                        type: 'is-warning'
            })
        },

        partialRowClass(row) {
            if (row.partialBookCount) {
                return 'has-background-warning-light'
            }
            return ""
        },

        fullRowClass(row) {
            if (row.lastTicketSold) {
                return 'has-background-warning-light'
            }
            return ""
        },
        
        statusIcon(row) {
            var icon = row.status
            switch (row.status) {
                case "RECEIVED":
                    icon = "lock"
                    row.scanAuditlocation = 'S'
                    break
                case "ONSALE":
                    if (row.location == 'M') {
                        row.scanAuditlocation = 'O' 
                        icon =  "store"
                    } else {
                        row.scanAuditlocation = 'I'
                        icon = "briefcase"
                    }
                    break
                case "CLOSED":
                    row.scanAuditlocation = 'C'
                    icon = "gem"
                    break
                case "RETURNED":
                    row.scanAuditlocation = 'R'
                    icon = "reply"
                    break
                case "LOST":
                    row.scanAuditlocation = 'L'
                    icon = "exclamation-triangle"
                    break                                                                  
            }
            return icon
        },

        statusIconTitle(row) {
            var title = row.status
            switch (row.status) {
                case "RECEIVED":
                    title = "In-Safe"
                    break
                case "ONSALE":
                    if (row.location == 'M') { 
                        title =  "Outside Sale"
                    } else {
                        title = "Inside Sale"
                    }
                    break
                case "CLOSED":
                    title = "Closed"
                    break
                case "RETURNED":
                    title = "Returned"
                    break                                                            
            }
            return title
        },

        async reCalculate() {

            // if (this.auditRecord.actualBookValueSafe == "" || this.auditRecord.actualBookValueSafe === null) {
            //     this.auditRecord.actualBookValueSafe = 0
            // }
            // if (this.auditRecord.actualBookValueRack == "" || this.auditRecord.actualBookValueRack === null) {
            //     this.auditRecord.actualBookValueRack = 0
            // }
            // if (this.auditRecord.actualBookValueMachine == "" || this.auditRecord.actualBookValueMachine === null) {
            //     this.auditRecord.actualBookValueMachine = 0
            // }
            // if (this.auditRecord.inventoryBookValueSafe == "" || this.auditRecord.inventoryBookValueSafe === null) {
            //     this.auditRecord.inventoryBookValueSafe = 0
            // }
            // if (this.auditRecord.inventoryBookValueRack == "" || this.auditRecord.inventoryBookValueRack === null) {
            //     this.auditRecord.inventoryBookValueRack = 0
            // }
            // if (this.auditRecord.inventoryBookValueMachine == "" || this.auditRecord.inventoryBookValueMachine === null) {
            //     this.auditRecord.inventoryBookValueMachine = 0
            // }  

            // this.numberFields.forEach(element => {
            //     this.auditRecord[element] = this.getNumber(this.auditRecord[element])
            // })
            
            // this.auditRecord.auditDayOutsideSales = this.getNumber(this.auditRecord.auditDayOutsideSales)
            
            // if (this.auditRecord.auditDayOutsideSales == "" || this.auditRecord.auditDayOutsideSales === null) {
            //     this.auditRecord.auditDayOutsideSales = 0
            // }

            this.auditRecord.totalAuditedValue = parseFloat(this.auditRecord.actualBookValueSafe) +
                        parseFloat(this.auditRecord.actualBookValueRack) +
                        parseFloat(this.auditRecord.actualBookValueMachine)

            // this.inventoryTotalValue = this.auditRecord.inventoryBookValueSafe
            //     + this.auditRecord.inventoryBookValueRack
            //     + this.auditRecord.inventoryBookValueMachine
                                              
        },          

    },

    computed: {
        isSaveDisabled() {
            if (this.auditRecord.auditerName && this.auditRecord.totalAuditedValue) {
                return false
            } else {
                return true
            }
        },

        firstDayOfAuditMonth() {
            return moment(this.auditRecord.auditDate).startOf('month')
        },

        auditMonthName() {
            return moment(this.firstDayOfAuditMonth).format('MMMM YYYY')
        },

        auditDateRange() {
            return [this.firstDayOfAuditMonth,this.auditRecord.auditDate]
        },
        
        inSafeBooks() {
            var allBooks = []
            this.groupedInSafeBooks.forEach((group) => {
                allBooks =  [...allBooks, ...group.books]
            })
            return allBooks
        },

        inSafeBooksScanned() {
            return this.inSafeBooks.filter((book) => {
                return book.scannedFlag
            })
        },

        inSafeBooksScannedValue() {
            return this.inSafeBooksScanned.reduce((sum, book) => sum + (book.pricePoint * (book.ticketCount - book.lastTicketSold)), 0)
        },

        totalValueAvailable() {
            return this.auditRecord.lastMonthEndInstantLotteryInventory 
                    + this.auditRecord.booksReceivedTotalValueBetweenAudits
                    - this.auditRecord.returnedTicketsTotalValueBetweenAudits
        },

        totalPeriodSales() {
            var total = this.auditRecord.insideTotalValueBetweenAudits + this.auditRecord.lotteryMachineWithdraw
            if (total) {
                return total * -1
            }
            return 0
        },

        inventoryExpectedValue() {
            return this.totalValueAvailable + this.totalPeriodSales
        },

        // inventoryTotalValue() {
        //     return this.auditRecord.inventoryBookValueSafe
        //         + this.auditRecord.inventoryBookValueRack
        //         + this.auditRecord.inventoryBookValueMachine
        // },

        actualInventoryVariance() {
            return this.balanceAdjustedWithTodaysTransactions 
                - this.inventoryExpectedValue 
        },

        balanceAdjustedWithTodaysTransactions() {
            return this.auditRecord.totalAuditedValue
                - this.getNumber(this.auditRecord.auditDayPurchase)
                + this.getNumber(this.auditRecord.auditDayOutsideSales)
                + this.getNumber(this.auditRecord.auditDayInsideSales)
                + this.getNumber(this.auditRecord.auditDayReturn)
        },

    },
    
}
</script>

<style scoped>
.textarea {
  height: 10px;
}
.veecli-table {
    border-bottom: 0.5px solid gray;
    padding-bottom: 2.5em;
    height: 3.5em;
}

.veecli-table input[type=number]  {

    background-color: rgb(183, 39, 39);
    text-align: center;
    font-weight: 900;
    font-size-adjust: calc(500%);
    color: rgb(0, 0, 0);
    width: 80px;
    border-color: gold;
    border-width: 2px;
}

.manual-count-input {
    background-color: rgb(208, 39, 39);
    text-align: center;
    font-weight: 900;
    font-size-adjust: calc(500%);
    color: rgb(0, 0, 0);
    width: 100px;
    border-color: gold;
    border-width: 2px;
}

.veecli-table-heading {
    border-bottom: 0.5px;
    padding-bottom: 2.5em;
    height: 2em;
    background-color: #f2f2f2d7;
    font-weight: bold;
    font-size: larger;
    text-align: center;
    vertical-align: middle;
}

.red-font {
    color: red;
}

</style>